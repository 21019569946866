.preview-image {
  cursor: pointer;
}

.select2-selection--is-disabled  {
  .select2-selection__control--is-disabled {
    background-color: #eff2f7;
    .select2-selection__single-value--is-disabled {
      color: #495057;
    }
  }
}

.date-picker-isEable {
  background-color: #ffffff !important;
  
  .form-control {
    background-color: #ffffff !important;
  }
}