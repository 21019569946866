.privacy-policy {
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
    padding-left: 0px !important;
  }
  
  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }
  
  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
    min-width: 50px;
    text-align: right;
  }
  
  li ol > li {
    margin: 0;
  }
  
  li ol > li:before {
    content: counters(item, ".") " ";
  }
}

